export const DOCUMENT_CATEGORY_IDENTITY = 1;
export const DOCUMENT_CATEGORY_RESIDENCY = 3;
export const DOCUMENT_CATEGORY_NAME_CHANGE = 4;
export const DOCUMENT_CATEGORY_RELATIONSHIP = 5;
export const DOCUMENT_CATEGORY_MDL_IDENTITY = 8;
export const DOCUMENT_CATEGORY_DRIVERS_LICENSE = 7;

export const FETCH_STATUS_IDLE = "idle";
export const FETCH_STATUS_SENDING = "sending";
export const FETCH_STATUS_SUCCESS = "success";
export const FETCH_STATUS_ERROR = "error";

export const FETCH_INTERVAL = 10000;

export const DEFAULT_MAX_UPLOAD_SIZE = 5;

export const APPLICATION_TYPE = {
  MDL: "mdl",
  COMMON: "Common",
  DV: 'dv',
  ODP: "odp",
  VDL: "vdl",
  CEC: "cec",
}

export const CATEGORY_TYPE = {
  'DV': 1,
  'MDL': 7
}

export const UNPROCESSABLE_ENTITY_STATUS_CODE = 422;