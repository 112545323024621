const DL_INPUT_LENGTH = 8;

const validateDate = (date) => {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
  }

export const validateDL = (obj) => {
    const { lastName, dlNumber, month, day, year } = obj;
    const isHasValue = lastName !== "" && dlNumber !== "";

    if (isHasValue) {
      const parsedYear = parseInt(year);
      const currentYear = new Date().getFullYear();

      if (isNaN(parsedYear) || parsedYear >= currentYear) {
        return false;
      }

      const dob = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      return validateDate(dob) && dlNumber.length === DL_INPUT_LENGTH;
    }

    return false;
  }
