export const status = {
  EXF: "EXF",
  CNT: "CNT",
  PUC: "PUC",
};

export const aidEnabledCatDocIds = [1, 2, 8, 60, 61, 62, 166];
export const multipPageCatDocIds = [2, 60, 8, 62, 166];

export const isBirthCertificate = (catDocId, docTypes) =>
  docTypes?.usBirthCertificate === catDocId;

export const isPassPort = (catDocId, docTypes) =>
  docTypes?.usPassport === catDocId;

export const isPassportCard = (catDocId, docTypes) =>
  docTypes?.usPassportCard === catDocId;

export const isDriversLicense = (catDocId, docTypes) =>
  docTypes?.drivingLicenseDocument === catDocId;

export const isAbbyyFlow = (ATD) => ["DNQ", "EXF", "XAD"].includes(ATD);

export const isMDLMobile = (applicationType) => applicationType === "mdl";

export const autoSubmitOptIn = {
  OPTED_IN: "optedIn",
  OPTED_OUT: "optedOut",
};

export const aidCaptureError = {
  errorCode1: "Face not detected.",
  errorCode2: "Hold still when uploading document to avoid blur.",
  errorCode3: "Avoid glare on the document.",
  errorCode4: "Barcode not detected.",
  errorCode99: "We encountered issues with your images. Please try again.",
};

export const cecBreadCrumbProps = {
  homeTitle: "Home",
  homeLink: process.env.REACT_APP_PORTAL_URL,
  currentPageTitle: "Online Driver's License Renewal Eligibility Check"
}